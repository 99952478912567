import { Order } from '@wix/ambassador-ecom-v1-order/types';
import { BookingDTO, Bookings, ServiceDTO, StaffDTO } from '../../../types';
import { OrderTransactions } from '@wix/ambassador-ecom-v1-order-transactions/types';

export const bookingsMapper = ({
  bookings,
  orders,
  transactions,
}: {
  bookings: Bookings;
  orders?: Order[];
  transactions?: OrderTransactions[];
}): Bookings => {
  const mappedBookings = {
    ...bookings,
    groups: bookings.groups.map((group) => {
      const { order, transaction } = getOrderAndTransaction(
        group.bookings[0].bookingId,
        orders || [],
        transactions || [],
      );

      const sortedBookings = mapAndSortBookings({
        groupBookings: group.bookings,
        offerings: bookings.relatedOfferings,
        staff: bookings.relatedStaff,
      });

      return {
        ...group,
        order,
        transaction,
        bookings: sortedBookings,
      };
    }),
  };

  return mappedBookings;
};

export const mapAndSortBookings = ({
  groupBookings,
  staff,
  offerings,
}: {
  groupBookings: BookingDTO[];
  staff: StaffDTO[];
  offerings: ServiceDTO[];
}): BookingDTO[] =>
  groupBookings
    .map((booking) =>
      mapOfferingAndStaffToBookingService({
        relatedOfferings: offerings,
        booking,
        relatedStaff: staff,
      }),
    )
    .sort(sortBookingsByDate);

export const mapOfferingAndStaffToBookingService = ({
  relatedOfferings,
  relatedStaff,
  booking,
}: {
  relatedOfferings: ServiceDTO[];
  relatedStaff: StaffDTO[];
  booking: BookingDTO;
}): BookingDTO => ({
  ...booking,
  service: relatedOfferings.find(
    (offering) => offering.id === booking.offeringId,
  )!,
  staffInfo: relatedStaff.find((staff) => staff.id === booking.staffId)!,
});

export const sortBookingsByDate = (a: BookingDTO, b: BookingDTO): number =>
  a.start - b.start;

export const getOrderAndTransaction = (
  bookingId: string,
  orders: Order[],
  transactions: OrderTransactions[],
) => {
  const relevantOrder = orders.find(
    (order) =>
      !!order.lineItems?.find(
        (lineItem) => lineItem.catalogReference?.catalogItemId === bookingId,
      ),
  );

  const relevantTransaction = transactions.find(
    (transaction) => transaction.orderId === relevantOrder?.id,
  );

  return {
    order: relevantOrder,
    transaction: relevantTransaction,
  };
};
