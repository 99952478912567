import { Order, OrderTransactions } from '@wix/ambassador-ecom-v1-order/types';
import { Api } from '../../../api';
import { Bookings } from '../../../types';

export const getOrdersAndTransactions = async ({
  api,
  data,
}: {
  api: Api;
  data: Bookings;
}): Promise<{ orders?: Order[]; transactions?: OrderTransactions[] }> => {
  const bookingIds = data.groups.map((group) => group.bookings[0].bookingId);
  const orders = (await api.queryOrders({ bookingIds })).orders;
  const orderIds =
    orders?.filter((order) => !!order.id).map((order) => order.id!) || [];
  const transactions = orderIds.length
    ? (await api.listTransactionsForMultipleOrders({ orderIds }))
        .orderTransactions
    : [];

  return { orders, transactions };
};
