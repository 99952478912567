import { CreateActionParams } from '../actions';

export type CloseModal = () => void;

export function createCloseModalAction({
  context,
  getControllerState,
}: CreateActionParams): CloseModal {
  return () => {
    const [, setState] = getControllerState();

    setState({ modalContext: undefined });
  };
}
