import { navigateToServiceList } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { CreateActionParams } from '../actions';

export type ClickOnEmptyStateButton = () => void;

export function createClickOnEmptyStateButtonAction({
  context,
}: CreateActionParams): ClickOnEmptyStateButton {
  return async () => {
    const { flowAPI } = context;
    const { wixCodeApi } = flowAPI.controllerConfig;

    try {
      await navigateToServiceList({ wixCodeApi });
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
