import { bookingsMapper } from '../../utils/mappers/bookings-mapper/bookings-mapper';
import { CreateActionParams } from '../actions';
import { getOrdersAndTransactions } from '../utils/getOrdersAndTransactions';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const [, setState] = getControllerState();
    const { api } = context;

    const upcomingData = await api.getUpcoming();

    const { orders, transactions } = await getOrdersAndTransactions({
      api,
      data: upcomingData,
    });

    setState({
      upcomingData: bookingsMapper({
        bookings: upcomingData,
        orders,
        transactions,
      }),
    });
  };
}
